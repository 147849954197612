import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

export default (el, props) => {
    const $el = $(el);
    const $stickers = $el.find('[data-draggable-sticker]');

    let moduleObserver = null;
    let isInited = false;

    const init = () => {
        moduleObserver = new IntersectionObserver(onModuleObserve, {
            threshold: 0.25
        });
        moduleObserver.observe(el);
    };

    const onModuleObserve = entries => {
        console.log('onModuleObserve');

        const intersecting = [];

        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });

        if (!intersecting.length) {
            return;
        }

        positionItems();

        Draggable.create($stickers.nodes, {
            bounds: $('[data-page-wrapper]')
                .get(0),
            type: 'x,y',
            inertia: true,
            throwResistance: 2000,
            onDragEnd: function () {
                const maxVel = Math.max(Math.abs(InertiaPlugin.getVelocity(this.target, 'x')), Math.abs(InertiaPlugin.getVelocity(this.target, 'y')));
                const maxRotation = Math.round(maxVel / 80);

                gsap.to(this.target, {
                    duration: this.tween.duration(),
                    rotation: Math.round(Math.random() * maxRotation) - (maxRotation / 2),
                    ease: 'sine.out'
                });
            }
        });

        moduleObserver.disconnect();
        moduleObserver = null;

        Viewport.on('resize', onResize);

        isInited = true;
    };

    const onResize = () => {
        positionItems(true);
    };

    const positionItems = isResize => {
        const itemsDistribution = [
            {
                x: ($el.width() / 6),
                y: ($el.height() / 6) * 1.5
            },
            {
                x: ($el.width() / 6) * 5,
                y: ($el.height() / 6) * 1.5
            },
            {
                x: ($el.width() / 6) * 1.5,
                y: ($el.height() / 6) * 4
            },
            {
                x: ($el.width() / 6) * 3,
                y: ($el.height() / 6) * 2.5
            },
            {
                x: ($el.width() / 6) * 5,
                y: ($el.height() / 6) * 4
            },
            {
                x: ($el.width() / 6) * 5,
                y: ($el.height() / 6) * 2.5
            },
            {
                x: ($el.width() / 6),
                y: ($el.height() / 6) * 2.5
            },
            {
                x: ($el.width() / 6) * 4,
                y: ($el.height() / 6) * 3.5
            },
            {
                x: ($el.width() / 6) * 4,
                y: ($el.height() / 6) * 2
            }
        ];

        const randomFactorWidth = Viewport.width / 7;
        const randomFactorHeight = Viewport.height / 7;

        $stickers.each((item, i) => {
            if (!isInited) {
                gsap.set(item, {
                    x: $el.width() / 2,
                    y: $el.height()
                });

                gsap.to(item, {
                    duration: 0.3,
                    opacity: 1,
                    rotation: (Math.random() * 60) - 30
                });
            }

            gsap.to(item, {
                duration: !isInited ? 1.8 : 0.4,
                x: itemsDistribution[i % itemsDistribution.length].x - 120 + ((Math.random() * randomFactorWidth) - randomFactorWidth / 2),
                y: itemsDistribution[i % itemsDistribution.length].y - 120 + ((Math.random() * randomFactorHeight) - randomFactorHeight / 2),
                rotation: (Math.random() * 40) - 20,
                ease: !isInited ? 'quart.out' : 'sine.out'
            });
        });
    };

    const destroy = () => {
        if (moduleObserver) {
            moduleObserver.disconnect();
            moduleObserver = null;
        }
    };

    return {
        init,
        destroy
    };
};
