import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();

    // Anchor links
    $('body')
        .on('click', 'a[href^="#"]:not([data-noanchor])', e => {
            const targetId = $(e.triggerTarget)
                .attr('href')
                .slice(1);
            const target = targetId ? $(`#${targetId}`)
                .get(0) : null;
            if (!target) {
                return true;
            }
            e.preventDefault();
            
            gsap.to(window, { duration: 1, scrollTo: { y: $(target).offset().top }, ease: 'quart.out' });

            return false;
        });

};

require('doc-ready')(init);
