import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const $el = $(el);
    
    const $logo = $el.find('[data-hero-logo]');
    const $payoff = $el.find('[data-hero-payoff]');
    const $menuItems = $el.find('[data-hero-menu-item]');
    
    const init = () => {
        gsap.set($logo.get(0), { opacity: 0, y: 40 });
        gsap.set($payoff.get(0), { opacity: 0 });
        gsap.set($menuItems.get(0), { opacity: 0 });
        gsap.set($menuItems.nodes, { opacity: 0, y: 50, rotation: 'random(10, -10)' });
        
        gsap.to($payoff.get(0), { delay: 0.4, duration: 0.3, opacity: 1 });
        gsap.to($logo.get(0), { delay: 0.5, duration: 1.6, opacity: 1, y: 0, ease: 'quart.out' });
        
        gsap.to($menuItems.nodes, { delay: 1, duration: 1.6, opacity: 1, y: 0, rotation: 0, ease: 'quart.out', stagger: 0.2 });
        
        //$el.on('')
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
